import React from "react";
import { Link } from "@reach/router";
import { Tooltip } from "react-tooltip";

const ToolsBreadcrumb = ({widget}) => {
  return (
    <>
      <div className="flex justify-between max-w-7xl mx-auto gap-3 px-4 2xl:px-0 items-center text-base text-[#494949] font-opensans font-semibold ">
        <Link to="/tools" className="flex gap-3 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="14"
            viewBox="0 0 8 14"
            fill="none"
          >
            <path
              d="M7 13L1 7L7 1"
              stroke="#575757"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <span className="text-[#B0B0B0]">Tools</span>
        </Link>{!widget && (
        <div className="flex items-center gap-2  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="20"
            viewBox="0 0 18 20"
            fill="none"
          >
            <path
              d="M9.34949 0L18 3.02768V10C18 13.5701 15.8088 16.0657 13.7647 17.6151C12.5317 18.5419 11.1785 19.2971 9.74223 19.8599L9.66697 19.8884L9.64534 19.8962L9.63929 19.8979L9.63669 19.8988C9.63583 19.8988 9.63496 19.8988 9.34949 19.083L9.06316 19.8997L9.0597 19.8979L9.05365 19.8962L9.03202 19.8875L8.95676 19.8599C8.54877 19.7021 8.1476 19.5272 7.75434 19.3356C6.76159 18.8534 5.81726 18.2772 4.93427 17.6151C2.89102 16.0657 0.698975 13.5701 0.698975 10V3.02768L9.34949 0ZM9.34949 19.083L9.06316 19.8997L9.34949 20L9.63583 19.8997L9.34949 19.083ZM9.34949 18.1557L9.35728 18.1522C10.5542 17.6575 11.684 17.0137 12.7197 16.2362C14.5692 14.8356 16.2699 12.7881 16.2699 10V4.25606L9.34949 1.83391L2.42908 4.25606V10C2.42908 12.7881 4.12977 14.8339 5.97925 16.237C7.01731 17.0161 8.14971 17.6607 9.34949 18.1557ZM14.6021 6.83651L8.48531 12.9533L4.81489 9.28374L6.03894 8.05969L8.48444 10.5069L13.378 5.61332L14.6021 6.83651Z"
              fill="#51CC56"
            />
          </svg>
          <p className="text-[#858585] font-semibold text-sm font-opensans">
            {" "}
            Your data is secure
          </p>
          <button
            data-tooltip-id="data-secure-tooltip"
            data-tooltip-content="Tomedes tools run on secure servers. General usage data is reviewed to improve the tools and user experience."
            className=""
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4ZM7.2 10.4H8.8V12H7.2V10.4ZM8.8 9.084V9.6H7.2V8.4C7.2 8.18783 7.28429 7.98434 7.43431 7.83431C7.58434 7.68429 7.78783 7.6 8 7.6C8.22726 7.59999 8.44985 7.53544 8.64186 7.41386C8.83387 7.29229 8.98741 7.11869 9.08462 6.91327C9.18182 6.70784 9.2187 6.47904 9.19095 6.25347C9.1632 6.02791 9.07196 5.81487 8.92787 5.63913C8.78377 5.46339 8.59273 5.33218 8.37698 5.26078C8.16122 5.18937 7.92963 5.18069 7.70914 5.23576C7.48865 5.29083 7.28833 5.40738 7.13149 5.57185C6.97465 5.73632 6.86774 5.94195 6.8232 6.1648L5.2536 5.8504C5.35091 5.36407 5.57561 4.9123 5.90474 4.54127C6.23387 4.17024 6.65561 3.89327 7.12687 3.73866C7.59813 3.58405 8.10199 3.55735 8.58695 3.66129C9.0719 3.76524 9.52056 3.99609 9.88706 4.33026C10.2535 4.66443 10.5247 5.08992 10.6729 5.56325C10.821 6.03658 10.8409 6.54076 10.7303 7.02425C10.6197 7.50774 10.3828 7.9532 10.0436 8.31509C9.70447 8.67698 9.27531 8.94233 8.8 9.084Z"
                fill="#B0B0B0"
              />
            </svg>
            <Tooltip
              id="data-secure-tooltip"
              place="bottom-end"
              multiline={true}
              className="z-50 !w-[300px] sm:!w-[400px] !font-opensans font-normal !text-sm !text-start"
            />
          </button>
        </div>
        )}
      </div>
    </>
  );
};

export default ToolsBreadcrumb;
