import React from "react";


const BannerSection = ({title,description,BannerData,text,BannerImage,isTranslatoir}) => {


  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-[80px] ">
        <div className={`${isTranslatoir ? "grid lg:grid-cols-2 gap-12 justify-between " : ""}`} >
        <h2 className={`text-[#0A2641] font-bold text-4xl font-primary ${isTranslatoir ? "text-center lg:text-start" : " text-center" }`} dangerouslySetInnerHTML={{__html:title}} />
      
        <p className={`text-[#424242] font-opensans text-lg  max-w-6xl mx-auto font-normal ${isTranslatoir ? "text-center lg:text-start mt-10 lg:mt-0" : " text-center mt-10" } `} dangerouslySetInnerHTML={{__html:description}}>
          
        </p>
        </div>
        <div className="lg:flex gap-8 items-center justify-between  rounded-lg my-10 bg-[#E0EBFF]">
          <div className="p-8 lg:w-[50%]">
            <p className="text-base font-opensans text-[#424242] ">
              With this tool, you can:
            </p>
            <ul className="mt-6 list-none">
              {BannerData.map((item, index) => (
                <li className="text-sm text-[#0A2641] font-opensans font-normal flex gap-2 py-1 ">
                  <div className="pt-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="9"
                      viewBox="0 0 12 9"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.8148 0.185212C11.9334 0.303839 12 0.46471 12 0.632448C12 0.800186 11.9334 0.961057 11.8148 1.07968L4.70329 8.19181C4.63867 8.25646 4.56195 8.30775 4.4775 8.34274C4.39306 8.37773 4.30255 8.39574 4.21114 8.39574C4.11973 8.39574 4.02922 8.37773 3.94478 8.34274C3.86033 8.30775 3.78361 8.25646 3.71899 8.19181L0.185382 4.65821C0.126609 4.59943 0.0799873 4.52966 0.0481793 4.45287C0.0163714 4.37608 6.19279e-10 4.29377 0 4.21065C-6.19279e-10 4.12754 0.0163714 4.04523 0.0481793 3.96844C0.0799873 3.89165 0.126609 3.82187 0.185382 3.7631C0.244156 3.70433 0.31393 3.65771 0.390721 3.6259C0.467512 3.59409 0.549817 3.57772 0.632935 3.57772C0.716053 3.57772 0.798357 3.59409 0.875149 3.6259C0.95194 3.65771 1.02171 3.70433 1.08049 3.7631L4.21177 6.89439L10.9197 0.185212C11.0383 0.0666208 11.1992 0 11.3669 0C11.5347 0 11.6962 0.0666208 11.8148 0.185212Z"
                        fill="#5B93FF"
                      />
                    </svg>
                  </div>
                  <p className="text-start" dangerouslySetInnerHTML={{ __html: item.text }} />
                </li>
              ))}
            </ul>
          </div>
          <div>
            <img src={BannerImage} alt="" />
          </div>
        </div>
        <p className="text-[#424242] font-opensans text-lg font-normal text-center max-w-6xl mx-auto pt-5" dangerouslySetInnerHTML={{__html:text}} />
    
      </div>
    </div>
  );
};

export default BannerSection;
