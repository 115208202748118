import Layout from "components/layout";
import BannerSection from "components/PostTranslation/BannerSection";
import SEO from "components/seo";
import ToolsBreadcrumb from "components/ToolsBredcrumb";
import React, { useEffect } from "react";
import BannerImage from "assets/translatorbanner.png"

const Index = () => {
  useEffect(() => {
    const handleIframeMessage = (event) => {
      // const id = event.data.message.share_id
      // const outputlang = event.data.message.targetlang
      // if(id){
      //   navigate(`/tools/mt-translation/result?share-id=${id}`)
      //   // const url = `http://localhost:3001/translations/${id}`;
      //   // window.open(url, "_blank");
      // }

      const allowedOrigins = ["https://iframe1.com", "https://iframe2.com"];
      if (!allowedOrigins.includes(event.origin)) return;

      const { type, data } = event.data;
      if (type === "iframe-success") {
        const id = data.message;

        // if (id) {
        //   navigate(`/tools/mt-translation/result?share-id=${id}`)
        //   // const url = `https://machine-translation-next-git-dev-bytequest.vercel.app/translations/${id}`;

        //   // window.open(url, "_blank");
        // }
      }
      if (type === "iframe-error") {
        console.error(data.message || "An error occurred in the iframe");
      }
    };

    window.addEventListener("message", handleIframeMessage);

    return () => window.removeEventListener("message", handleIframeMessage);
  }, []);

  const BannerData = [
    {
      text: "<span class='font-bold'>Translate texts, documents, PDFs, and images instantly</span> with AI-powered precision.",
    },
    {
      text: "<span class='font-bold'>Access several high-quality translations</span>from multiple Large Language Models (LLMs) and AI translation sources.",
    },
    {
      text: "<span class='font-bold'>Customize and refine translations</span> using the <span class='font-bold'>Translation Agent</span>feature, which learns and adapts to your preferences.",
    },
    {
      text: "<span class='font-bold'>Compare translations </span> side by side to ensure accuracy and contextual relevance.",
    },
    {
      text: "<span class='font-bold'>Preserve terminology consistency</span> across projects with automated <span class='font-bold'>Key Term Translations. </span>",
    },
    {
      text: "<span class='font-bold'>Translate up to 100,000 words for free</span> covering most professional and personal needs.",
    },
    {
      text: "<span class='font-bold'>Ensure high translation accuracy with AI Quality Scores,</span> where higher scores mean better quality overall.",
    },
  ];

  return (
    <Layout>
      <SEO
        title="Free AI Translator - Translate Text, PDFs & Documents Online"
        description="Use our free AI translator online to translate texts, documents, and PDFs instantly. Get high-quality AI-powered translations with no sign-up required and enjoy 100,000 free words."
        slug="/tools/ai-translator"
      />
      <div className="bg-[#F5F5F5] pb-24">
        <div className="pt-7">
          <ToolsBreadcrumb widget={true} />
        </div>
        <iframe
          width="100%"
          height="600"
          src={`https://www.machinetranslation.com/widget`}
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className=""
          loading="lazy"
        ></iframe>
      
       
      </div>
      <div className="bg-white ">
          <div className="max-w-7xl mx-auto p-4">
            <BannerSection
              title="Get Free AI Translations and Compare Results from the Best AI Sources"
              description=" The Tomedes AI Translator is a powerful tool that allows users to instantly translate text, full documents, PDFs, and even images - all for free.<br /><br/> It provides fast, accurate, and almost unlimited translations in over 270 languages, making it one of the best AI translators
              available online.
              <br /><br/>
              All users can translate up to 100,000 words at no cost and with no
              sign-up requirements."

              BannerData={BannerData}
              BannerImage={BannerImage}
              text="Enjoy one of the most accessible AI translators available today with free, high-volume translation capabilities. It's perfect for businesses, students, and professionals. Start translating now - no sign-up needed."
             isTranslatoir={true}
           />
          </div>
        </div>
    </Layout>
  );
};

export default Index;
